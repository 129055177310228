import React from 'react';
import BaseTemplate from '../../../components/templates/BaseLayout';
import SEO from '../../../components/atoms/utility/seo';
import Spacer from '../../../components/atoms/utility/Spacer';
import EventsListBlogV2 from '../../../components/organisms/EventsListBlogV2';
import { austinEvents } from '../../../content/events/austin-events';

function AustinDowntown() {
  return (
    <BaseTemplate>
      <SEO
        title={'Downtown Austin'}
        description={'Come find events downtown in Austin'}
      />
      <Spacer sizeY={1} className="" />
      <EventsListBlogV2
        cityName="Austin"
        categoryPage={'Downtown'}
        eventsData={austinEvents}
      />
      <Spacer sizeY={1} className="" />
    </BaseTemplate>
  );
}

export default AustinDowntown;
